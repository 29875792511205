import { memo, useEffect, useRef } from "react";
import styled from "styled-components";
import videojs from "video.js";

import "video.js/dist/video-js.css";

const StyledVideo = styled.video`
    width: 100%;
    height: 100%;
`;

interface PlayerProps {
    options: videojs.PlayerOptions;
    className?: string;
}

const Player = (props: PlayerProps) => {
    const { options, className } = props;

    const videoRef = useRef(null);

    // This separate functional component fixes the removal of the player element
    // from the DOM when calling the dispose() method on a player
    const VideoWrapper = () => (
        <div className={className}>
            <StyledVideo
                ref={videoRef}
                className="video-js vjs-big-play-centered"
            />
        </div>
    );

    useEffect(() => {
        const videoElement = videoRef.current;
        let player: videojs.Player | undefined;
        if (videoElement) {
            player = videojs(videoElement, options);
        }
        return () => {
            if (player) {
                player.dispose();
            }
        }
    }, [options]);

    return <VideoWrapper />;
}

export default memo(Player);
