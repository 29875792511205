import styled from "styled-components";

const Title = styled.div`
  padding: 32px 32px 0;
  font-size: 20px;
  font-weight: bold;
`;

const Item = styled.a`
  display: block;
  padding: 32px;
  box-shadow: 0 4px 10px 1px #eee;
  margin: 32px;
  border-radius: 16px;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 20px;
`;

const HomePage = () => {
    return (
        <div>
            <Title>Home</Title>
            <Item href={'/rss?url=http://myrss.nu/drama/category/hk-drama'}>HK Drama</Item>
        </div>
    );
}

export default HomePage;
