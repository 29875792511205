import history from './navigationHistory';

class NavigationUtils {
    public static navigateTo = (route: string) => history.push(route);

    public static goBack = () => history.goBack();

    public static replace = (route: string) => history.replace(route);

    public static currentLocation = () => history.location;

    // public static generateRoutes = <T extends navigation.Routes>(
    //     baseRoute: string,
    //     routes: T,
    // ): T => {
    //     const mappedRoutes = Object.entries(routes)
    //         .reduce(
    //             (obj, [key, route]) => Object.assign(obj, { [key]: `${baseRoute}${route}` }),
    //             {} as T,
    //         );
    //     return Object.freeze(mappedRoutes);
    // };
    //
    // public static appRouteConfigs = (
    //     path: string,
    //     component: ComponentType<RouteComponentProps<any>> | ComponentType<any>,
    //     configs: Omit<navigation.AppRouteConfigs, 'path' | 'component'> = {},
    // ): navigation.AppRouteConfigs => ({
    //     path,
    //     exact: true,
    //     component,
    //     ...configs,
    // });
}

export default NavigationUtils;
