import { RouteComponentProps } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Episodes } from "../../finder/finder";
import axios from "axios";
import NavigationUtils from "../../navigation/src/navigationUtils";

interface RouteParams {
    url: string;
}

type VideoAllPageProps = RouteComponentProps<RouteParams>;

const VideoAllPage = (props: VideoAllPageProps) => {
    const { url } = props.match.params;

    const [episodes, setEpisodes] = useState<Episodes>();

    useEffect(() => {
        axios.get(`https://movie-hub-api.hypecircle.workers.dev/episodes?url=${url}`).then((response) => {
            if (response.data) {
                setEpisodes(response.data);
            }
        });
    }, [url]);

    const onEpisodeClick = useCallback((videoUrl) => () => {
        NavigationUtils.navigateTo(`/video/watch/${encodeURIComponent(videoUrl)}`);
    }, []);

    if (!episodes) {
        return <Loading />;
    }

    return (
        <div>
            <div>Video All</div>
            {Object.entries(episodes).map(([episodeName, url]) => {
                return (
                    <a key={episodeName} onClick={onEpisodeClick(url)}>
                        {episodeName}
                    </a>
                );
            })}
        </div>
    );
}

const Loading = () => <>Loading</>;

export default VideoAllPage;
