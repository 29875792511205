import styled from "styled-components";
import { ChangeEvent, useCallback, useState } from "react";
import NavigationUtils from "../../navigation/src/navigationUtils";

const Input = styled.input`
    padding: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
`;

const Button = styled.button`
    padding: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
`;

interface SearchBoxProps {
    query?: string;
}

const SearchBox = (props: SearchBoxProps) => {
    const [query, setQuery] = useState(props.query ?? '');

    const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    }, []);

    const onSearch = useCallback(() => {
        NavigationUtils.navigateTo(`/search/${query}`);
    }, [query]);

    return (
        <div>
            <Input
                value={query}
                onChange={onChange}
                placeholder="Search..."
            />
            <Button
                type="submit"
                onClick={onSearch}
            >
                Search
            </Button>
        </div>
    );
}

export default SearchBox;
