import { RouteComponentProps } from "react-router-dom";
import SearchBox from "./SearchBox";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { SearchResult } from "../../finder/finder";
import styled from "styled-components";
import NavigationUtils from "../../navigation/src/navigationUtils";

const ResultCard = styled.div`
  box-shadow: 0 2px 4px 4px #eee;
  margin: 16px;
  padding: 16px;
  border-radius: 16px;
  cursor: pointer;
`;

const VideoThumbnail = styled.img`
  width: 200px;
`;

interface RouteParams {
    query?: string;
}

type SearchPageProps = RouteComponentProps<RouteParams>;

// @TODO: Don't navigate if same page?
const SearchPage = (props: SearchPageProps) => {
    const { query } = props.match.params;

    const [result, setResult] = useState<SearchResult>();

    useEffect(() => {
        if (query) {
            axios.get(`https://movie-hub-api.hypecircle.workers.dev/search?query=${query}`).then((response) => {
                if (response.data) {
                    setResult(response.data);
                }
            });
        }
    }, [query]);

    const onResultClick = useCallback(() => {
        NavigationUtils.navigateTo(`/video/all/${encodeURIComponent(result?.mainUrl ?? '')}`);
    }, [result]);

    return (
        <div>
            <div>Search</div>
            <SearchBox query={query} />
            {Boolean(result) && (
                <ResultCard onClick={onResultClick}>
                    <VideoThumbnail src={result?.thumbnail} />
                    <div>{result?.mainUrl}</div>
                    <div>{result?.title}</div>
                    <div>{result?.casts}</div>
                    <div>{result?.description}</div>
                    <div>{result?.type}</div>
                    <div>{result?.status}</div>
                </ResultCard>
            )}
        </div>
    );
}

export default SearchPage;
