import Player from "../../player/views/Player";
import styled from "styled-components";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { VideoSource } from "../../finder/finder";
import { useQuery } from "../../rss/views/RssPage";

const Page = styled.div`
  padding: 20px;
`;

interface RouteParams {
    url: string;
}

type VideoWatchPageProps = RouteComponentProps<RouteParams>;

const VideoWatchPage = (props: VideoWatchPageProps) => {
    const query             = useQuery();
    const url               = query.get("url") ?? "";

    // const { url } = props.match.params;
    // const [source, setSource] = useState<string | null>(null);
    //
    // useEffect(() => {
    //     // TODO: check .m3u8 or page url (check if valid url)
    //     const isM3U8Source = /^https?:\/\/.+\.m3u8$/.test(url);
    //
    //     if (!isM3U8Source) {
    //         axios.get(`https://movie-hub-api.hypecircle.workers.dev/video?url=${url}`).then((response) => {
    //             const videoSource = response.data as VideoSource | undefined;
    //             console.log(videoSource, typeof videoSource)
    //             if (videoSource?.source) {
    //                 setSource(videoSource.source);
    //             } else {
    //                 setSource('');
    //             }
    //         });
    //     } else {
    //         setSource(url);
    //     }
    // }, [url]);
    //
    // if (source === null) {
    //     return <Loading />
    // }
    //
    // if (!source) {
    //     return <InvalidSource />;
    // }

    return (
        <Page>
            <Player
                options={{ // lookup the options in the docs for more options
                    autoplay  : false, // TODO:
                    controls  : true,
                    responsive: true,
                    fluid     : true,
                    preload   : "auto",
                    sources   : [{
                        // src : 'https://europe.olemovienews.com/hlstimeofffmp4/20210617/mxjqdinG/mp4/mxjqdinG.mp4/master.m3u8',
                        // src : source,
                        src : url,
                        type: 'application/x-mpegURL'
                    }]
                }}
            />
        </Page>
    );
};

const EmptySource = () => <>Empty Source</>;

const InvalidSource = () => <>Invalid Source</>;

const Loading = () => <>Loading</>;

export default VideoWatchPage;
