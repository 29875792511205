import { Route, Router, Switch } from 'react-router-dom';
import navigationHistory from '../src/navigationHistory';
import VideoWatchPage from "../../video/views/VideoWatchPage";
import HomePage from "../../home/views/HomePage";
import SearchPage from "../../search/views/SearchPage";
import VideoAllPage from "../../video/views/VideoAllPage";
import RssPage from "../../rss/views/RssPage";

const NavigationRouter = () => (
    <Router history={navigationHistory}>
        <Switch>
            {/*{NavigationPages.map((configs) => <Route key={configs.path} {...configs} />)}*/}
            <Route path="/" exact component={HomePage} />
            <Route path="/search/:query?" exact component={SearchPage} />
            <Route path="/video/watch" exact component={VideoWatchPage} />
            <Route path="/video/all/:url" exact component={VideoAllPage} />
            <Route path="/rss" exact component={RssPage} />
            <Route path="*">
                404 not found
            </Route>
        </Switch>
    </Router>
);

export default NavigationRouter;
