import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const Item = styled.a`
  display: block;
  padding: 32px;
  box-shadow: 0 4px 10px 1px #eee;
  margin: 32px;
  border-radius: 16px;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 20px;
`;

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const RssPage = () => {
    const query             = useQuery();
    const url               = query.get("url") ?? "";
    const [items, setItems] = useState<MyRssItem[]>([]);

    useEffect(() => {
        axios.get<MyRss>(`https://movie-hub-api.hypecircle.workers.dev/rss?url=${url}`)
            .then((response) => {
                if (response.data) {
                    const items = response.data.rss.channel.item.reduce<MyRssItem[]>((uniqueItems, currentItem) => {
                        const duplicatedItem = uniqueItems.find((item) => item.enclosure["@_url"] === currentItem.enclosure["@_url"]);
                        return !duplicatedItem ? [...uniqueItems, currentItem] : uniqueItems;
                    }, []);
                    setItems(items);
                }
            });
    }, []);

    if (!url) {
        return <InvalidUrl />;
    }

    return (
        <div>
            {items.map((item, index) => {
                const itemUrl  = item.enclosure["@_url"];
                const itemType = item.enclosure["@_type"];
                const isRss    = itemType === 'application/rss+xml';
                const isVideo  = itemType === 'video/html';

                if (isRss) {
                    return (
                        <Item href={`/rss?url=${itemUrl}`} key={itemUrl}>
                            {item.title}
                        </Item>
                    )
                }

                if (isVideo) {
                    return (
                        <Item href={itemUrl} key={itemUrl}>
                            {item.title}
                        </Item>
                    )
                }
            })}
        </div>
    );
}

const InvalidUrl = () => <>Invalid URL</>;

export interface MyRss {
    rss: {
        channel: {
            item: MyRssItem[];
        }
    }
}

export interface MyRssItem {
    title: string;
    description: string;
    enclosure: {
        "@_url": string;
        "@_type": string;
    }
}

export default RssPage;
