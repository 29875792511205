import './App.css';
import NavigationRouter from './navigation/views/NavigationRouter';

function App() {
    return (
        <NavigationRouter />
    );
}

export default App;
